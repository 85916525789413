import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  loyaltyHero,
  loyaltyTrusted,
  loyaltyWhiteGlove,
  loyaltyLargeFeatures,
  loyaltyBusinessTypesCar,
  loyaltyVideo,
  loyaltySolutionsSection,
  loyaltyTestimonials,
  loyaltyCTA,
  loyaltySolutionsSlides,
  // loyaltyFaqs,
} from "../../../data/product-pages/loyalty-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
import {
  activmealsSlides,
  heroSuccess,
} from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "loyalty-wg.png";

const loyalty = () => {
  return (
    <Layout>
      <SEO
        title="Restaurant Loyalty Software For Businesses | SpotOn"
        description="SpotOn's all-in-one mobile & desktop systems come with built-in customer loyalty programs for any business type. Demo our point of sale software."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/products/loyalty/"
      />
      <Hero sectionData={loyaltyHero} circleBg={false} />
      <TrustedNumbers numbersArray={loyaltyTrusted} style={{ marginTop: 32 }} />
      <WhiteGlove
        sectionData={loyaltyWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={loyaltyLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <BusinessTypes sectionData={loyaltyBusinessTypesCar} />
      <SuccessStories sectionData={heroSuccess} slides={activmealsSlides} />
      <VideoSection sectionData={loyaltyVideo} />
      <PartnerLogos />
      <ProductLines
        sectionData={loyaltySolutionsSection}
        productSlides={loyaltySolutionsSlides}
      />
      <TestmonialReviews sectionData={loyaltyTestimonials} />
      <PartnerLogos />
      <LargeCta sectionData={loyaltyCTA} />
      {/* <Faqs sectionData={loyaltyFaqs} /> */}
    </Layout>
  );
};

export default loyalty;
