/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const loyaltyHero = {
  title: "Reward your customers. Grow. Repeat",
  subtext:
    "Increase profits by rewarding your customers with what they love most about your business to drive repeat visits.",
  heroImg: "loyalty-hero.png",
  imageClassName: "top-adjustement",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/loyalty/demo",
  },
  fatTitle: "Loyalty",
};

export const loyaltyTrusted = [
  {
    title: "5+ Million",
    subtext: "Redemptions using SpotOn",
  },
  {
    title: "25 – 95%",
    subtext: "Profit growth with a 5% increase in customer retention",
  },
  {
    title: "31%",
    subtext:
      "Increased spending from repeat customers compared to new customers",
  },
  {
    title: "50%",
    subtext: "Loyal customers are more likely to try new products",
  },
];

export const loyaltyWhiteGlove = {
  mainTitle: "Digital loyalty with a human touch",
  title: "Our team is here to help",
  content:
    "Your customers expect you to give them a modern guest experience while still being the warm and friendly local business they trust. Why should you expect anything less from the companies that serve you? At SpotOn, we’ll not only provide you with a digital loyalty program that’s easy for you and your customers, we’ll also be there with you every step of the way with local service and 24/7 lifetime support included.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/loyalty/demo",
  },
};

export const loyaltyLargeFeatures = {
  sectionTitle: "The hassle-free loyalty program",
  featureBlocks: [
    {
      blockTitle: "3 easy steps to success",
      blockImg: "loyalty-a.png",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/loyalty/demo",
      },
    },
    {
      blockTitle: "Keep the line moving fast",
      blockSubTitle:
        "Our integrated payment hardware makes it fast and easy for your customers to enroll in your loyalty rewards program during checkout, earn Spots with every purchase, and then redeem their Spots for rewards. Customers simply type in their phone number, scan a code using the free SpotOn consumer mobile app, or scan a traditional loyalty card.",
      blockImg: "loyalty-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/loyalty/demo",
      },
    },
    {
      blockTitle: "Grow your customer base",
      blockSubTitle:
        "With every new customer that enrolls in your loyalty program, you’ll collect valuable contact information, allowing you to easily communicate with your customers using SpotOn Marketing to improve customer loyalty and drive repeat visits.",
      blockImg: "loyalty-c.png",
      blockList: [
        "Enrollment rewards",
        "Limited-time deals",
        "Email marketing",
        "Facebook campaigns",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/loyalty/demo",
      },
    },
    {
      blockTitle: "Work faster & smarter",
      blockImg: "loyalty-d.png",
      blockList: [
        "Improve retention with automated campaigns to customers who haven’t visited in a while.",
        "Automatically reward customers for their \n" +
          "birthdays and other occasions.",
        "View performance analytics on all your rewards\n" + "and campaigns.",
        "Work from anywhere with the SpotOn \n" +
          "Business Dashboard mobile app.",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/loyalty/demo",
      },
    },
  ],
};

export const loyaltyBusinessTypesCar = {
  title: "Loyalty made simple",
  subtext:
    "Keep your customers coming back with a loyalty rewards program that practically runs itself, so you can focus on other important aspects of your business.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurants",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const loyaltyVideo = {
  title: "Keep customers coming back for more",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  videoUrlId: "7I3implX9xc",
};

export const loyaltySolutionsSection = {
  title: "More tools to improve loyalty",
  subText: `Get more from your SpotOn Loyalty program by using it alongside these other powerful tools.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const loyaltyTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const loyaltyIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept loyaltys and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const loyaltyCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/loyalty/demo",
  },
};

export const loyaltyFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const loyaltySolutionsSlides = [
  {
    title: "Restaurant",
    img: "Restaurant.png",
    link: "/restaurants",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
];
